// Global styling for this template

html,
body {
  height: 100%;
}

// Set spacing on containers for page symmetry

.container,
.container-fluid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}
.button-container {
  .btn {
    width: 100%; /* Ancho fijo del botón */
    max-width: 200px; /* Establece el ancho máximo del botón según tus necesidades */
    margin-bottom: 5px; /* Ajusta el margen inferior según desees */
  }
}
